var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"bg",style:({ minHeight: _vm.boxheight })},[_c('div',{staticStyle:{"font-size":"18px"}},[_vm._v("我的錢包")]),_c('p',{staticStyle:{"margin-bottom":"10px","margin-top":"20px","text-align":"left"}},[_vm._v(" 租借前需綁定付款方式，歸還後通過綁定方式扣款 ")]),_c('div',{staticClass:"card"},[_c('van-cell',{staticStyle:{"font-weight":"500"},attrs:{"title":"請選擇付款方式"}}),_c('van-radio-group',{on:{"change":_vm.onChange},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_c('van-cell-group',{attrs:{"border":false}},[_c('van-cell',{attrs:{"title":"支付押金","clickable":""},on:{"click":function($event){_vm.radio = '1'}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"1"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"22px","height":"22px","src":props.checked
                                                ? _vm.activeIcon
                                                : _vm.inactiveIcon}})]}}])})]},proxy:true}])}),_c('van-cell',{attrs:{"title":"免付押金","clickable":""},on:{"click":function($event){_vm.radio = '2'}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"2"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"22px","height":"22px","src":props.checked
                                                ? _vm.activeIcon
                                                : _vm.inactiveIcon}})]}}])})]},proxy:true}])}),_c('van-cell',{attrs:{"title":"訂閱制","clickable":""},on:{"click":function($event){_vm.radio = '3'}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"3"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"22px","height":"22px","src":props.checked
                                                ? _vm.activeIcon
                                                : _vm.inactiveIcon}})]}}])})]},proxy:true}])})],1)],1)],1),_c('div',{staticClass:"tip"},[_c('p',[_vm._v("說明：")]),_c('p',[_vm._v("支付押金：需支付420元押金（歸還時可自行退款），若七天未歸還則沒收押金。")]),_c('p',[_vm._v("免付押金：無需支付押金，但若七天未歸還則由帳號扣除420元。")]),_c('p',[_vm._v(" 訂閱制：預先支付費用，可享訂閱期間不限時間與次數，無限制使用。 ")]),_c('div',{staticClass:"login-way"},[_c('div',{staticClass:"sms-way",on:{"click":_vm.confirm}},[_vm._v("確認")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }